.page-header {
    @apply space-y-2;

    .__tag {
        @apply -mb-1.5 block text-xs font-bold uppercase tracking-tight text-slate-400;
    }

    .__children {
        @apply space-y-4;
    }

    .heading {
        @apply text-3xl font-bold tracking-tighter md:text-5xl;
    }

    .heading--2 {
        @apply text-2xl font-bold tracking-tighter md:text-4xl;
    }

    .heading--3 {
        @apply text-xl font-semibold tracking-tighter md:text-2xl;
    }

    .sub-heading {
        @apply text-slate-400 text-base lg:text-lg;
    }

    &.page-header--white {
        .heading,
        .heading--2,
        .heading--3 {
            @apply text-white;
        }

        .sub-heading {
            @apply text-slate-400;
        }
    }

    &.page-header--black {
        .heading,
        .heading--2,
        .heading--3 {
            @apply text-slate-800;
        }

        .sub-heading {
            @apply text-slate-600;
        }
    }

    &.page-header--secondary {
        .heading,
        .heading--2,
        .heading--3,
        .sub-heading {
            @apply text-cerulean-950;
        }
    }

    &.page-header--left {
        .heading,
        .heading--2,
        .heading--3 {
            @apply text-left;
        }

        .sub-heading {
            @apply text-left;
        }

        .__children {
            @apply flex flex-col items-start;
        }
    }

    &.page-header--center {
        .heading,
        .heading--2,
        .heading--3 {
            @apply text-center;
        }

        .sub-heading {
            @apply text-center;
        }

        .__children {
            @apply flex flex-col items-center;
        }
    }

    &.page-header--right {
        .heading,
        .heading--2,
        .heading--3 {
            @apply text-right;
        }

        .sub-heading {
            @apply text-right;
        }

        .__children {
            @apply flex flex-col items-end;
        }
    }

    &.page-header--with-divider {
        @apply border-b border-cerulean-900 pb-4 lg:pb-8;
    }

    &.page-header--with-children {
        @apply space-y-2 lg:space-y-4;
    }

    &.page-header--white {
        .heading--link {
            @apply text-bright-turquoise-500 transition duration-200 hover:text-cerulean-500 underline underline-offset-2;
        }
    }
}
